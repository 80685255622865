// use jsx to render html, do not modify simple.html

import ScrollAnim from "rc-scroll-anim";
import React from "react";
import ReactDOM from "react-dom";
import QueueAnim from "rc-queue-anim";
import TweenOne from "rc-tween-one";
import { Col, Row, Card, Typography, Image, Alert, Input, Flex } from "antd";

import "./animation.less";

type BannerProps = {
  Title: string;
  Description: string;
};

const AnimatedBanner = ({ Title, Description }: BannerProps) => {
  return (
    <div className="pack-page page0">
      <QueueAnim className="home-title">
        <div className="page-title" key="title">
          <p>{Title}</p>
        </div>
        <div className="page-description" key="c">
          <p>{Description}</p>
        </div>
      </QueueAnim>
    </div>
  );
};

export default AnimatedBanner;
