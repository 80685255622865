import React, { useContext } from 'react'

import { StoreContext } from '../Store'
import pic from "../Images/logo.png"

const WyvernLogoHeader = () => {
  const context = useContext(StoreContext)

  return (
    <span >
      <img src={pic} className='app-header-logo' />
    </span>
  )
}

export default WyvernLogoHeader
