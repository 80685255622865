import React, { useEffect, useState } from "react";
import { DailyWeather } from "../Types/Weather";
import { Card, Image, Flex } from "antd";
import logo from "../Images/weatherPlaceHolder.png";

import { Icon } from "../Components/Icon";
import { selectUrl } from "../CityPage";
import { useParams } from "react-router-dom";
import { on } from "events";

type WeatherGirlCardProps = {
  weather: DailyWeather;
  onClick: () => void;
  index: number;
  className: string | undefined;
  imageUrl: string;
};

const { Meta } = Card;

const WeatherGirlCard = ({
  weather,
  onClick,
  index,
  imageUrl,
}: WeatherGirlCardProps) => {
  const [imgUrl, setImgUrl] = useState(logo);

  useEffect(() => {
    setImgUrl(imageUrl);
  });

  return (
    <Card
      key={index}
      onClick={onClick}
      hoverable={true}
      className="app-card app-card-detail-card"
      cover={
        <Image
          preview={false}
          src={imgUrl}
          fallback={logo}
          draggable={false}
        ></Image>
      }
    >
      <Meta
        title={
          <Flex gap={"small"}>
            <div>{new Date(weather.date).toDateString()}</div>
            <Icon size={32} weather={weather} color="#000" />
          </Flex>
        }
      />
    </Card>
  );
};
export default WeatherGirlCard;
