import React, { useContext } from 'react'
import { useMapEvent, useMap } from 'react-leaflet/hooks'
import { StoreContext } from '../Store'
import { Image } from 'antd'

import {
  MapContainer,
  TileLayer,
  Polygon,
  Polyline,
  Circle,
  FeatureGroup,
  Marker,
  Popup,
} from 'react-leaflet'
import { EditControl } from 'react-leaflet-draw'

const convertPointToLatLng = (point) => {
  return [point[1], point[0]]
}

const convertLineToLatLng = (line) => {
  let newLine = []
  for (let i = 0; i < line.length; i++) {
    newLine.push(convertPointToLatLng(line[i]))
  }
  return newLine
}

const convertPolygonToLatLng = (polygon) => {
  let newPolygon = []
  for (let i = 0; i < polygon.length; i++) {
    newPolygon.push(convertLineToLatLng(polygon[i]))
  }
  return newPolygon
}

const disableInteractionsConfig = {
  zoomControl: false,
  dragging: false,
  doubleClickZoom: false,
  scrollWheelZoom: false,
  boxZoom: false,
  keyboard: false,
  tap: false,
  touchZoom: false,
  touchRotate: false,
  attributionControl: false,
}
const noDisableInteractionsConfig = {}

const checkDisableInteractions = (disableInteractions) => {
  if (disableInteractions) {
    return disableInteractionsConfig
  } else {
    return noDisableInteractionsConfig
  }
}

const Map = ({
  imageOverlays = [],
  disableInteractions = false,
  height = 300,
  zoom = 2,
  clearMap = false,
  useRenderer = true,
  markers = [],
  setClearMap = () => null,
  center = [9.537029, -13.67729],
}) => {
  const MapRenderer = ({ center, zoom }) => {

    const map = useMap()
    map.setView(center, zoom)
  }

  if (clearMap) {
    setClearMap(false)
    return <MapContainer></MapContainer>
  }

  return (
    <MapContainer

      style={{ height: height }}
      {...checkDisableInteractions(disableInteractions)}
      center={center}
      zoom={zoom}
    >
      {/* <MapRenderer center={center} zoom={zoom} useRenderer={useRenderer} /> */}

      {markers.map((marker, index) => {
        return (
          <Marker
            key={index}
            position={marker.coords}
            onClick={marker.onClick}
          >
            <Popup className="app-popup">
              <Image src={marker.img} preview={false} onClick={marker.onClick} />
              <h>{marker.popup}</h>
            </Popup>
          </Marker>
        )
      })}

      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {imageOverlays}


    </MapContainer>
  )
}

export default Map
