
import {City} from './Types/City'

export const mock_city = {
  "id": "9f0a34f3-cfc7-4f54-96d5-a19386169681",
  "daily_weather": [
    {
      "id": "79460ac4-48d6-4d78-8400-df6ed7bb8942",
      "created_at": "2024-01-20T19:47:53.976618Z",
      "updated_at": "2024-01-20T19:47:53.976625Z",
      "is_deleted": false,
      "date": "2024-01-19",
      "weather_code": 3,
      "temperature_2m_max": -0.004499998,
      "temperature_2m_min": -3.2045,
      "apparent_temperature_max": -4.2242084,
      "apparent_temperature_min": -7.306447,
      "sunrise": 0,
      "sunset": 0,
      "daylight_duration": 30081.088,
      "sunshine_duration": 12576.375,
      "uv_index_max": 1.35,
      "uv_index_clear_sky_max": 1.35,
      "precipitation_sum": 0,
      "rain_sum": 0,
      "showers_sum": 0,
      "snowfall_sum": 0,
      "precipitation_hours": 0,
      "precipitation_probability_max": 8,
      "wind_speed_10m_max": 16.595179,
      "wind_gusts_10m_max": 44.639996,
      "wind_direction_10m_dominant": 252.77573,
      "shortwave_radiation_sum": 2.78,
      "et0_fao_evapotranspiration": 0.26066938,
      "image_url": null,

      "man_image_url": null,
      "woman_image_url": null,
      "cat_image_url": null,
      "dog_image_url": null,
      "city": "9f0a34f3-cfc7-4f54-96d5-a19386169681"
    },
    {
      "id": "db2c40dc-fb3a-432e-b3b8-01381f1bd7dc",
      "created_at": "2024-01-20T19:47:53.982725Z",
      "updated_at": "2024-01-20T20:37:42.075921Z",
      "is_deleted": false,
      "date": "2024-01-20",
      "weather_code": 3,
      "temperature_2m_max": 1.089,
      "temperature_2m_min": -1.961,
      "apparent_temperature_max": -3.4522684,
      "apparent_temperature_min": -6.7278523,
      "sunrise": 0,
      "sunset": 0,
      "daylight_duration": 30251.535,
      "sunshine_duration": 22813.436,
      "uv_index_max": 1.35,
      "uv_index_clear_sky_max": 1.35,
      "precipitation_sum": 0,
      "rain_sum": 0,
      "showers_sum": 0,
      "snowfall_sum": 0,
      "precipitation_hours": 0,
      "precipitation_probability_max": 13,
      "wind_speed_10m_max": 15.542662,
      "wind_gusts_10m_max": 37.44,
      "wind_direction_10m_dominant": 245.32674,
      "shortwave_radiation_sum": 3.82,
      "et0_fao_evapotranspiration": 0.46331263,
      "image_url": null,

      "man_image_url": null,
      "woman_image_url": null,
      "cat_image_url": null,
      "dog_image_url": null,
      "city": "9f0a34f3-cfc7-4f54-96d5-a19386169681"
    },
    {
      "id": "74d06d60-6e18-49c4-90c9-cb09f2020675",
      "created_at": "2024-01-20T19:47:53.997626Z",
      "updated_at": "2024-01-20T20:37:42.094707Z",
      "is_deleted": false,
      "date": "2024-01-22",
      "weather_code": 61,
      "temperature_2m_max": 5.639,
      "temperature_2m_min": -0.361,
      "apparent_temperature_max": 0.46628094,
      "apparent_temperature_min": -5.398101,
      "sunrise": 0,
      "sunset": 0,
      "daylight_duration": 30604.838,
      "sunshine_duration": 0,
      "uv_index_max": 0.1,
      "uv_index_clear_sky_max": 1.3,
      "precipitation_sum": 7.4999995,
      "rain_sum": 7.4999995,
      "showers_sum": 0,
      "snowfall_sum": 0,
      "precipitation_hours": 12,
      "precipitation_probability_max": 71,
      "wind_speed_10m_max": 31.093666,
      "wind_gusts_10m_max": 74.159996,
      "wind_direction_10m_dominant": 211.52448,
      "shortwave_radiation_sum": 0.83,
      "et0_fao_evapotranspiration": 0.93695945,
      "image_url": null,

      "man_image_url": null,
      "woman_image_url": null,
      "cat_image_url": null,
      "dog_image_url": null,
      "city": "9f0a34f3-cfc7-4f54-96d5-a19386169681"
    },
    {
      "id": "c2c3ea02-ec39-4015-9da1-2ff277d8d5ce",
      "created_at": "2024-01-20T19:47:54.004011Z",
      "updated_at": "2024-01-20T20:37:42.105158Z",
      "is_deleted": false,
      "date": "2024-01-23",
      "weather_code": 61,
      "temperature_2m_max": 9.169499,
      "temperature_2m_min": 2.9195,
      "apparent_temperature_max": 6.4048424,
      "apparent_temperature_min": -0.8767066,
      "sunrise": 0,
      "sunset": 0,
      "daylight_duration": 30787.115,
      "sunshine_duration": 0,
      "uv_index_max": 0.95,
      "uv_index_clear_sky_max": 1.2,
      "precipitation_sum": 7.299999,
      "rain_sum": 6.9999995,
      "showers_sum": 0,
      "snowfall_sum": 0,
      "precipitation_hours": 11,
      "precipitation_probability_max": 48,
      "wind_speed_10m_max": 17.992796,
      "wind_gusts_10m_max": 43.92,
      "wind_direction_10m_dominant": 218.1076,
      "shortwave_radiation_sum": 1.83,
      "et0_fao_evapotranspiration": 0.34369308,
      "image_url": null,

      "man_image_url": null,
      "woman_image_url": null,
      "cat_image_url": null,
      "dog_image_url": null,
      "city": "9f0a34f3-cfc7-4f54-96d5-a19386169681"
    },
    {
      "id": "c2c3ea02-ec39-4015-9da1-2ff277d8d5ce",
      "created_at": "2024-01-20T19:47:54.004011Z",
      "updated_at": "2024-01-20T20:37:42.105158Z",
      "is_deleted": false,
      "date": "2024-01-23",
      "weather_code": 61,
      "temperature_2m_max": 9.169499,
      "temperature_2m_min": 2.9195,
      "apparent_temperature_max": 6.4048424,
      "apparent_temperature_min": -0.8767066,
      "sunrise": 0,
      "sunset": 0,
      "daylight_duration": 30787.115,
      "sunshine_duration": 0,
      "uv_index_max": 0.95,
      "uv_index_clear_sky_max": 1.2,
      "precipitation_sum": 7.299999,
      "rain_sum": 6.9999995,
      "showers_sum": 0,
      "snowfall_sum": 0,
      "precipitation_hours": 11,
      "precipitation_probability_max": 48,
      "wind_speed_10m_max": 17.992796,
      "wind_gusts_10m_max": 43.92,
      "wind_direction_10m_dominant": 218.1076,
      "shortwave_radiation_sum": 1.83,
      "et0_fao_evapotranspiration": 0.34369308,
      "image_url": null,

      "man_image_url": null,
      "woman_image_url": null,
      "cat_image_url": null,
      "dog_image_url": null,
      "city": "9f0a34f3-cfc7-4f54-96d5-a19386169681"
    },
    {
      "id": "c2c3ea02-ec39-4015-9da1-2ff277d8d5ce",
      "created_at": "2024-01-20T19:47:54.004011Z",
      "updated_at": "2024-01-20T20:37:42.105158Z",
      "is_deleted": false,
      "date": "2024-01-23",
      "weather_code": 61,
      "temperature_2m_max": 9.169499,
      "temperature_2m_min": 2.9195,
      "apparent_temperature_max": 6.4048424,
      "apparent_temperature_min": -0.8767066,
      "sunrise": 0,
      "sunset": 0,
      "daylight_duration": 30787.115,
      "sunshine_duration": 0,
      "uv_index_max": 0.95,
      "uv_index_clear_sky_max": 1.2,
      "precipitation_sum": 7.299999,
      "rain_sum": 6.9999995,
      "showers_sum": 0,
      "snowfall_sum": 0,
      "precipitation_hours": 11,
      "precipitation_probability_max": 48,
      "wind_speed_10m_max": 17.992796,
      "wind_gusts_10m_max": 43.92,
      "wind_direction_10m_dominant": 218.1076,
      "shortwave_radiation_sum": 1.83,
      "et0_fao_evapotranspiration": 0.34369308,
      "image_url": null,

      "man_image_url": null,
      "woman_image_url": null,
      "cat_image_url": null,
      "dog_image_url": null,
      "city": "9f0a34f3-cfc7-4f54-96d5-a19386169681"
      
    },
    {
      "id": "c2c3ea02-ec39-4015-9da1-2ff277d8d5ce",
      "created_at": "2024-01-20T19:47:54.004011Z",
      "updated_at": "2024-01-20T20:37:42.105158Z",
      "is_deleted": false,
      "date": "2024-01-23",
      "weather_code": 61,
      "temperature_2m_max": 9.169499,
      "temperature_2m_min": 2.9195,
      "apparent_temperature_max": 6.4048424,
      "apparent_temperature_min": -0.8767066,
      "sunrise": 0,
      "sunset": 0,
      "daylight_duration": 30787.115,
      "sunshine_duration": 0,
      "uv_index_max": 0.95,
      "uv_index_clear_sky_max": 1.2,
      "precipitation_sum": 7.299999,
      "rain_sum": 6.9999995,
      "showers_sum": 0,
      "snowfall_sum": 0,
      "precipitation_hours": 11,
      "precipitation_probability_max": 48,
      "wind_speed_10m_max": 17.992796,
      "wind_gusts_10m_max": 43.92,
      "wind_direction_10m_dominant": 218.1076,
      "shortwave_radiation_sum": 1.83,
      "et0_fao_evapotranspiration": 0.34369308,
      "image_url": null,
      "man_image_url": null,
      "woman_image_url": null,
      "cat_image_url": null,
      "dog_image_url": null,
      "city": "9f0a34f3-cfc7-4f54-96d5-a19386169681"
    }
  ],
  "created_at": "2024-01-20T19:18:16.376734Z",
  "updated_at": "2024-01-20T19:18:16.376747Z",
  "is_deleted": false,
  "name": "Ouahigouya",
  "country": "Burkina Faso",
  "lattitude": 13.58278,
  "longitude": -2.42158,
  "population": 124587,
  "image_url": null,
} as City
