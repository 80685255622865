import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { Button, ConfigProvider, Input, Space, theme } from 'antd';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <ConfigProvider
      // Customizing theme: https://ant.design/docs/react/customize-theme#api
      theme={{
        token: {
          colorPrimary: '#3b26f9',
          colorSuccess: '#00aa51',
          colorInfo: '#3b26f9',
          colorWarning: '#f48403',
          colorError: '#ff4848',
          fontFamily: 'Acumin Pro, sans-serif',
        },
        algorithm: theme.darkAlgorithm
      }}
    >
      <App />
    </ConfigProvider>
  </React.StrictMode>,
)
