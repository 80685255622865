// use jsx to render html, do not modify simple.html

import React from "react";
import QueueAnim from "rc-queue-anim";
import dog from "../Images/humane_dog.png";
import cat from "../Images/humane_cat.jpeg";
import logo from "../Images/weatherPlaceHolder.png";
import { useNavigate } from "react-router-dom";
import { Col, Row, Card, Typography, Image, Alert, Input, Flex } from "antd";
import { OverPack } from "rc-scroll-anim";

import "./animation.less";
const { Meta } = Card;
const { Title } = Typography;

const AnimatedPage = () => {
  const navigate = useNavigate();

  return (
    <div className="page2">
      <OverPack playScale={0.4} className="page-elements-container-page2">
        <QueueAnim key="queue" type="bottom">
          <div
            className="page-title page-elements-title margin-top"
            key="title"
          >
            <p>Adopt a new family member!</p>
          </div>
          <div className="page-description page-elements-title " key="c">
            <p>Edmonton Humane Society</p>
          </div>

          <Flex wrap="wrap" key="b" align="center" justify="center">
            <Card
              onClick={() => {
                window.location.href =
                  "https://www.edmontonhumanesociety.com/adoptables/";
              }}
              className="app-card"
              hoverable
              cover={
                <Image
                  preview={false}
                  src={dog}
                  width={200}
                  height={200}
                  fallback={logo}
                  draggable={false}
                ></Image>
              }
            >
              <Meta
                title={<Flex gap="small">{"Dogs"}</Flex>}
                description={"Fetch a good boi"}
              />
            </Card>

            <Card
              onClick={() => {
                window.location.href =
                  "https://www.edmontonhumanesociety.com/adoptables/";
              }}
              hoverable
              className="app-card"
              cover={
                <Image
                  preview={false}
                  src={cat}
                  width={200}
                  height={200}
                  fallback={logo}
                  draggable={false}
                ></Image>
              }
            >
              <Meta
                title={<Flex gap="small">{"Cats"}</Flex>}
                description={"Sacrifice Yourself"}
              />
            </Card>
          </Flex>
        </QueueAnim>
      </OverPack>
    </div>
  );
};

export default AnimatedPage;
