// use jsx to render html, do not modify simple.html

import React from "react";
import QueueAnim from "rc-queue-anim";
import dog from "../Images/dog.png";
import cat from "../Images/cat.png";
import logo from "../Images/weatherPlaceHolder.png";
import { useNavigate } from "react-router-dom";
import { Col, Row, Card, Typography, Image, Alert, Input, Flex } from "antd";
import { OverPack } from "rc-scroll-anim";

import "./animation.less";
const { Meta } = Card;
const { Title } = Typography;

const AnimatedPage = () => {
  const navigate = useNavigate();

  return (
    <div className="page2">
      <OverPack playScale={0.4} className="page-elements-container-page1">
        <QueueAnim key="queue" type="bottom">
          <div
            className="page-title page-elements-title margin-top"
            key="title"
          >
            <p>Check Out The AI Critters!</p>
            <div className="page-description page-elements-title" key="a">
              <p>For cities with population over 100,000.</p>
            </div>
          </div>

          <Flex wrap="wrap" key="b" justify="center" align="center">
            <Card
              onClick={() => {
                navigate(`/app/Dogs`);
              }}
              hoverable
              className={"animal-card"}
              cover={
                <Image
                  preview={false}
                  src={dog}
                  fallback={logo}
                  draggable={false}
                ></Image>
              }
            >
              <Meta
                title={<Flex gap="small">{"Dogs"}</Flex>}
                description={"Check out the Dogos!"}
              />
            </Card>

            <Card
              onClick={() => {
                navigate(`/app/Cats`);
              }}
              hoverable
              className={"animal-card"}
              cover={
                <Image
                  preview={false}
                  src={cat}
                  fallback={logo}
                  draggable={false}
                ></Image>
              }
            >
              <Meta
                title={<Flex gap="small">{"Cats"}</Flex>}
                description={"Check out the Kitties"}
              />
            </Card>
          </Flex>
        </QueueAnim>
      </OverPack>
    </div>
  );
};

export default AnimatedPage;
