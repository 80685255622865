import { WiCelsius } from 'weather-icons-react';

import { Flex } from "antd"

const gap = 5
export const Direction = (props) => {
    return (
        <Flex gap={gap}>
            {Math.floor(props.children)}
            <span className="app-unit-base">°</span>
        </Flex >
    )
}


export const Temperature = (props) => {

    const temperature = Math.floor(props.children)
    return (
        <>
            {Math.floor(temperature)}
            <span className="app-unit-base">°C</span>
        </>
    )
}

export const Precipitation = (props) => {

    return (
        <Flex gap={gap}>
            {Math.floor(props.children)}
            <span className="app-unit-base">mm</span>
        </Flex>
    )
}

export const Percentage = (props) => {
    return (
        <Flex gap={gap}>
            {Math.floor(props.children)}
            <span className="app-unit-base">%</span>
        </Flex>
    )
}


export const Wind = (props) => {
    return (
        <Flex gap={gap}>
            {Math.floor(props.children)}
            <span className="app-unit-base">km/h</span>
        </Flex>
    )
}

export const ShortWaveRadiationSum = (props) => {
    return (
        <Flex gap={gap}>
            {props.children}
            <span className="app-unit-base">MJ/m²</span>
        </Flex>
    )
}

// seconds
export const Duration = (props) => {

    let seconds = props.children
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    seconds = Math.floor(seconds % 60);

    return (
        <Flex gap={gap}>
            {hours}<span className="app-unit-base">h</span>
            {minutes}<span className="app-unit-base">m</span>
            {seconds}<span className="app-unit-base">s</span>
        </Flex>

    )
}