import { OverPack } from 'rc-scroll-anim';
import TweenOne from 'rc-tween-one';
import QueueAnim from 'rc-queue-anim';
import React from 'react';
import github_white from '../Images/Github_white.svg'
import LinkedIN_white from '../Images/LinkedIN_white.svg'
import Resume from '../Images/Resume.png'
import { Image, Flex } from 'antd';

const defaultProps = {
    component: 'div',
    playScale: 0.5,
    always: true,
    // scrollEvent: noop,
    replay: false,
    // onChange: noop,
    // onScroll: noop,
    appear: true,
    componentProps: {},
}

const gap = 10
const Footer = () => {
    return (
        <div className='footer-page'>

            <OverPack playScale={.2} className="page-elements-container-footer" >

                <QueueAnim key="queue" type="bottom" >

                    <div className="page-elements-title margin-top" key="title">
                        <p>Adopt a Software Engineer</p>
                    </div>

                    <Flex wrap="wrap" justify='center' align='center' key="a">
                        <Flex className="footer-elements-container" justify='center' align='center' gap={gap}>
                            <a className='footer-element' href="https://www.linkedin.com/in/evan-giese-47192bba/"><p>LinkedIn</p></a>
                            <a className='footer-element' href="https://www.linkedin.com/in/evan-giese-47192bba/">
                                <Image preview={false} draggable={false} src={LinkedIN_white} />
                            </a>
                        </ Flex>

                        <Flex wrap="wrap" className="footer-elements-container" justify='center' align='center' gap={gap}>
                            <Flex justify='center' align='center' gap={gap}><a className='footer-element' href="https://github.com/EvanGee"><p>Github</p></a>
                                <a className='footer-element' href="https://github.com/EvanGee">
                                    <Image preview={false} draggable={false} src={github_white}></Image>
                                </a>
                            </Flex>
                        </Flex>

                        <Flex wrap="wrap" className="footer-elements-container" justify='center' align='center' gap={gap}>
                            <a className='footer-element' href="https://evangee.github.io/EvanGee/">
                                <p>Resume</p>
                            </a>
                            <a className='footer-element' href="https://evangee.github.io/EvanGee/">
                                <Image preview={false} draggable={false} src={Resume}></Image>
                            </a>
                        </Flex>

                    </Flex>




                </QueueAnim >
            </OverPack >

        </div >
    );
}
export default Footer