// import './App.scss'
import React, { useContext, useEffect } from 'react'
import { StoreContext } from './Store'
import axios from 'axios'

// This is some boilerplate code to retrieve the value of a named cookie.
// We need it to retrieve a csrf token that must be passed with all post
// requests or they will be rejected by the server with a 403.
const getCookie = (name) => {
  var cookieValue = null
  if (document.cookie && document.cookie !== '') {
    var cookies = document.cookie.split(';')
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim()
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
        break
      }
    }
  }
  return cookieValue
}

// Add the X-CSRFToken to all requests, all non-GET requests require
// this is they will be rejected by the server with a 403
const api = axios.create({
  baseURL: '/',
  headers: {
    'X-CSRFToken': getCookie('csrftoken'),
  },
})
export const getGeoinformation = (params) => api.get(`/api/geoinformation`, { params })

export const getCurrentUser = () => api.get(`/accounts/current/`)
export const getCurrentEnv = () => api.get(`/accounts/environment/`)
export const generateImage = (params) => api.get(`/api/generate_image/`, { params })
export const getCity = (params) => api.get(`/api/city/`, { params })
export const getCityById = (id) => api.get(`/api/city/${id}/`)
export const getCityDetail = (id) => api.get(`/api/city_detail/${id}/`)

export const getIpCoords = async () => {

  const data = await axios.get("https://api.ipify.org/?format=json");
  console.log(data);
  const ip = data.data.ip;
  await axios.get("https://api.ip2location.io/?key={YOUR_API_KEY}&ip=" + ip);
}

export async function initializationCalls(context) {
  // This is for testing locally. We can mock the user and
  // render 'most' of the front end without a backend.
  // however, we can't commit this code because process is not defined in github actions,
  // this won't pass unit tests

  // if (process.env.NODE_ENV != 'development') {
  //   const userData = await getCurrentUser()
  //   const user = userData.data
  // }

  // comment out these 2 lines, and include the block above to test locally
  // const userData = await getCurrentUser()
  // const envData = await getCurrentEnv()
  // const user = userData.data
  // context.update({
  //   user: user,
  //   environment: envData.data.environment,
  // })
}

function Api() {
  const context = useContext(StoreContext)

  useEffect(() => {
    initializationCalls(context)
  }, [])

  return <></>
}

export default Api
