import React from 'react';
import { Alert, Space } from 'antd';


type AppProps = {
    onClose: () => void
    errorMessage: string
    errorDescription: string
}

export const Error = ({onClose, errorMessage, errorDescription} : AppProps) => (

    <Space direction="vertical" style={{ width: '100%' }}>
        <Alert
        message={errorMessage}
        description={errorDescription}
        type="error"
        closable
        onClose={onClose}
        />
    </Space>
);

export default Error;