// use jsx to render html, do not modify simple.html

import ScrollAnim from "rc-scroll-anim";
import React from "react";
import ReactDOM from "react-dom";
import QueueAnim from "rc-queue-anim";
import TweenOne from "rc-tween-one";
import { Col, Row, Card, Typography, Image, Alert, Input, Flex } from "antd";
import appImage from "../Images/AppImage.png";
import appImageCity from "../Images/AppImageCity.png";
import "./animation.less";
import { OverPack } from "rc-scroll-anim";

type BannerProps = {
  Title: string;
  Description: string;
};

const AnimatedAppBanner = ({ Title, Description }: BannerProps) => {
  return (
    <div className="page1">
      <OverPack playScale={0.4} className="page-elements-container-page1">
        <QueueAnim key="queue" type="bottom">
          <Flex wrap="wrap" key="b" justify="center" align="center">
            <div
              className="page-title page-elements-title app-text"
              key="title"
            >
              <p>{Title}</p>
              <div className="page-description" key="c">
                <p>{Description}</p>
              </div>
            </div>

            <Image
              preview={false}
              draggable={false}
              src={appImage}
              height={"50em"}
              className="banner-image"
            ></Image>
          </Flex>
        </QueueAnim>
      </OverPack>
    </div>
  );
};

export default AnimatedAppBanner;
