import React, { } from 'react'
import { useNavigate } from 'react-router-dom'
import { Col, Row, Card, Typography, Image, Alert, Input, Flex } from 'antd'
import AnimatedBanner from './AnimatedBanner'
import Footer from '../Components/Footer'
import AnimatedPage from "./AnimatedPage"
import AnimatedPageHumaneSociety from "./AnimatedPageHumaneSociety"
import AnimatedAppBanner from "./AnimatedAppBanner"

const { Title } = Typography
const { Meta } = Card

const HomePage = () => {
  return <div >
    <AnimatedBanner Title="It's Raining Cats and Dogs" Description="A purrfect way to deliver the weather forecast." />
    <AnimatedAppBanner Title="Very Soon On Android" Description="Ios To Be Announced." />
    <AnimatedPage />
    <Footer />
  </div>

}
export default HomePage
