import React, { useEffect, useState } from "react";
import { Card, Image, Spin, Flex } from "antd";

export const Loading = () => {
  // return <Flex align="center" gap="middle">
  //     <Spin size="large" className="app-spin"/>
  // </Flex>

  return <Spin size="large" className="app-spin" />;
};

export default Loading;
