import './App.scss'
import Store from './Store'
import Home from './Home'
import CityPage from './CityPage'

import Header from './Header'
import Download from './Download'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import React, { useState } from 'react'
import WeatherDetail from './CityDetail' 

function App() {

  return (
    <Store>
        <div className="App">
          <BrowserRouter>
          <Header></Header>
            <Routes>
              <Route path="/app">
                <Route index element={<Home></Home>} />
                <Route path=":type/city/:id" element={<WeatherDetail />} />
                <Route path=":type" element={<CityPage />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </div>
    </Store>
  )
}

export default App
