import React, { useEffect, useState, useContext, Component } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { Col, Row, Card, Typography, Image, Alert, Input, Pagination } from 'antd'
import Map from '../Leaflet'
import { getIpCoords, getCity } from '../Api'
import { Loading } from "../Components/Loading"

import CityCard from './CityCard'
import { UrlTypes } from '../Types/Weather'
import { mock_city } from '../mockData'
import { Error } from '../Components/Error'
import logo from "../Images/weatherPlaceHolder.png"
import Footer from "../Components/Footer"

const { Search } = Input
const { Meta } = Card
const { Title } = Typography


export const selectUrl = (weather, type) => {
  if (type === UrlTypes.WOMAN) {
    return weather?.woman_image_url ? weather.woman_image_url : logo
  } else if (type === UrlTypes.MEN) {
    return weather?.man_image_url ? weather.man_image_url : logo
  } else if (type === UrlTypes.DOG) {
    return weather?.dog_image_url ? weather.dog_image_url : logo
  } else if (type === UrlTypes.CAT) {
    return weather?.cat_image_url ? weather.cat_image_url : logo
  }
  else {
    return weather?.image_url ? weather.cat_image_url : logo
  }
}


const CityPage = () => {
  const [searchValue, setSearchValue] = useState('')
  const [debouncingTimeout, setDebouncingTimeout] = useState(false)
  const { type } = useParams()
  const [cities, setCities] = useState([mock_city])
  const [markers, setMarkers] = useState([])
  const [center, setCenter] = useState()
  const [loading, setLoading] = useState(true)
  const [errorDescription, setErrorDescription] = useState("")
  const [errorMessage, setErrorMessage] = useState('')
  const [isError, setIsError] = useState(false)
  const [clearMap, setClearMap] = useState(false)
  const [page, setPage] = useState(1)
  const [cityCount, setCityCount] = useState(1)

  const navigate = useNavigate()


  const renderCities = () => {

    if (cities.length == 0) {
      return <div>No cities found</div>
    }

    return cities.map((city, index) => <CityCard index={`${index}_cityCard`} imageUrl={selectUrl(city?.daily_weather[0], type)} city={city} onClick={() => navigate(`/app/${type}/city/${city.id}`)}></CityCard>)

  }

  useEffect(() => {
    var params = new URLSearchParams()
    params.append('search', searchValue)
    params.append("page", page)

    setClearMap(true)

    // get data, we put it in an async function so we can use await rather than promise syntax.
    const fetchData = async () => {
      const data = await getCity(params)

      if (data.data.count == 0) {
        setCities([])
        setLoading(false)
        return
      }

      setMarkers([])
      setCities(data.data.results)
      setCenter([data.data.results[0].lattitude, data.data.results[0].longitude])
      setCityCount(data.data.count)

      for (let i = 0; i < data.data.results.length; i++) {
        let city = data.data.results[i]
        let marker = {
          coords: [city.lattitude, city.longitude], popup: city.name, img: selectUrl(city.daily_weather[0], type), onClick: () => navigate(`/app/${type}/city/${city.id}`)
        }
        setMarkers(markers => [...markers, marker])

      }
      setLoading(false)

    }

    fetchData().catch((error) => {
      setErrorMessage('Error')
      setErrorDescription(error)
      setLoading(false)

    })

  }, [searchValue, page, type])

  return (
    <div className='app-city-page'>
      <Map
        height={400}
        disableInteractions={false}
        center={center}
        markers={markers}>
      </Map>
      <Search
        allowClear
        placeholder={'Search for a country...'}
        size="large"
        onChange={(e) => {
          if (debouncingTimeout) {
            clearTimeout(debouncingTimeout)
          }
          setDebouncingTimeout(
            setTimeout(() => {
              setSearchValue(e.target.value)
              setPage(1)
            }, 500),
          )
        }}
      />
      <Row justify="center" align={"middle"}>
        {loading && <Loading />}
        {isError && <Error onClose={() => setIsError(false)} errorMessage={errorMessage} errorDescription={errorDescription} />}
      </Row>

      <Row>



        {renderCities()}
      </Row>

      <Pagination style={{ textAlign: "end", margin: "1rem" }} defaultCurrent={1} pageSize={18} current={page} onChange={(value) => { setPage(value) }} total={cityCount}></Pagination>
      <Footer />

    </div >
  )
}
export default CityPage
