import { useParams, useNavigate, Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { getIpCoords, getCityById, getCityDetail } from "../Api";
//types
import { DailyWeather } from "../Types/Weather";
import { City } from "../Types/City";

import WeatherGirlCard from "./WeatherCard";
import WeatherGirlCardMain from "./WeatherCardMain";
import { mock_city } from "../mockData";
import { Layout, Row, Col, Image, Card, Button, Carousel, Flex } from "antd";
import { selectUrl } from "../CityPage";
import { Loading } from "../Components/Loading";
import { Error } from "../Components/Error";
import { on } from "events";
import { CarouselRef } from "antd/es/carousel";
import Footer from "../Components/Footer";

const { Content } = Layout;

type CityDetailParameters = {
  type: string;
  id: string;
};

const organizeByDate = (mockWeather: DailyWeather[]) => {
  const sortedWeather = mockWeather.sort((a, b) => {
    let date1 = new Date(a.date);
    let date2 = new Date(b.date);
    return +date1 - +date2;
  });

  return sortedWeather;
};

//TODO turn this into something
const CityDetailPage = () => {
  const navigate = useNavigate();
  const { type, id } = useParams<CityDetailParameters>();
  const [weekForcast, setweekForcast] = useState<DailyWeather[]>(
    mock_city.daily_weather.filter((w: DailyWeather, i: number) => i !== 0)
  );
  const [todayWeather, setTodayWeather] = useState<DailyWeather>(
    mock_city.daily_weather[0]
  );
  const [loading, setLoading] = useState(true);
  const [city, setCity] = useState<City>(mock_city);

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorDescription, setErrorDescription] = useState("");
  const [currentSlide, setCurrentSlide] = useState(0);

  const onSlideChange = (current: any) => {
    setCurrentSlide(current);
  };

  const slider = useRef<CarouselRef | null>();
  useEffect(() => {
    // // get data, we put it in an async function so we can use await rather than promise syntax.
    const fetchData = async () => {
      const data = await getCityDetail(id);
      console.log(data);
      const city = data.data;

      const sortedWeather = organizeByDate(city?.daily_weather);
      setweekForcast(sortedWeather);
      setTodayWeather(sortedWeather[0]);
      setCity(city);
      setLoading(false);
    };

    fetchData().catch((error) => {
      console.log(error);
      setLoading(false);
    });
  }, []);

  return (
    <div
      style={{
        backgroundImage: `url(${todayWeather?.city_image_url})`,
        backgroundColor: "#133252",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Carousel
        style={{}}
        afterChange={onSlideChange}
        speed={1000}
        ref={(ref) => {
          slider.current = ref;
        }}
      >
        {weekForcast.map((d, i) => (
          <WeatherGirlCardMain
            city={city}
            weather={d}
            onClick={(id: string) => {
              console.log("clicked");
            }}
            index={i}
            imageUrl={selectUrl(d, type)}
          />
        ))}
      </Carousel>

      <Row className="app-row-margins" align={"middle"} justify={"center"}>
        {loading && <Loading />}
        {isError && (
          <Error
            onClose={() => setIsError(false)}
            errorMessage={errorMessage}
            errorDescription={errorDescription}
          />
        )}
      </Row>

      <Flex wrap="wrap">
        {weekForcast.map((d, i) => (
          <WeatherGirlCard
            className=""
            weather={d}
            onClick={() => {
              slider?.current?.goTo(i);
            }}
            index={i}
            imageUrl={selectUrl(d, type)}
          />
        ))}
      </Flex>

      <Row className="app-row-margins">
        <br></br>
      </Row>

      <Footer />
    </div>
  );
};

export default CityDetailPage;
