
import {
    wiCloud,
    WiDaySunny,
    WiDayCloudy,
    WiDayCloudyHigh,
    WiDayCloudyGusts,
    WiDayCloudyWindy,
    WiDayFog,
    WiDayHail,
    WiDayHaze,
    WiDayLightning,
    WiDayLightWind,
    WiDayRain,
    WiDayRainMix,
    WiDayRainWind,
    WiDayShowers,
    WiDaySleet,
    WiDaySleetStorm,
    WiDaySnow,
    WiDaySnowThunderstorm,
    WiDaySnowWind,
    WiDaySprinkle,
    WiDayStormShowers,
    WiDaySunnyOvercast,
    WiDayThunderstorm,
    WiDayWindy,
    WiSolarEclipse,
    WiHot,
    WiNightClear,
    WiNightCloudy,
    WiNightCloudyHigh,
    WiNightCloudyGusts,
    WiNightCloudyWindy,
    WiNightFog,
    WiNightHail,
    WiNightLightning,
    WiNightRain,
    WiNightRainMix,
    WiNightRainWind,
    WiNightShowers,
    WiNightSleet,
    WiNightSleetStorm,
    WiNightSnow,
    WiNightSnowThunderstorm,
    WiNightSnowWind,
    WiNightSprinkle,
    WiNightStormShowers,
    WiNightThunderstorm,
    WiNightWindy,
    WiBarometer,
    WiCelsius,



} from 'weather-icons-react';

// "weather_code": 3,
// "temperature_2m_max": 5.089,
// "temperature_2m_min": 0.139,
// "apparent_temperature_max": 1.8998928,
// "apparent_temperature_min": -2.8237815,
// "sunrise": 0,
// "sunset": 0,
// "daylight_duration": 31743.734,
// "sunshine_duration": 26845.842,
// "uv_index_max": 1.15,
// "uv_index_clear_sky_max": 1.5,
// "precipitation_sum": 0,
// "rain_sum": 0,
// "showers_sum": 0,
// "snowfall_sum": 0,
// "precipitation_hours": 0,
// "precipitation_probability_max": 3,
// "wind_speed_10m_max": 9.255571,
// "wind_gusts_10m_max": 19.44,
// "wind_direction_10m_dominant": 172.30795,
// "shortwave_radiation_sum": 4.97,
// "et0_fao_evapotranspiration": 0.53010947,

/*
0	Clear sky
1, 2, 3	Mainly clear, partly cloudy, and overcast
45, 48	Fog and depositing rime fog
51, 53, 55	Drizzle: Light, moderate, and dense intensity
56, 57	Freezing Drizzle: Light and dense intensity
61, 63, 65	Rain: Slight, moderate and heavy intensity
66, 67	Freezing Rain: Light and heavy intensity
71, 73, 75	Snow fall: Slight, moderate, and heavy intensity
77	Snow grains
80, 81, 82	Rain showers: Slight, moderate, and violent
85, 86	Snow showers slight and heavy
95 *	Thunderstorm: Slight or moderate
96, 99 *	Thunderstorm with slight and heavy hail
*/

//https://najens.github.io/weather-icons-react/
export function Icon({ weather, size, color }) {

    if (!weather) {
        return <WiBarometer className="app-icon-base" />;
    }

    switch (weather.weather_code) {
        case 0: return <WiDaySunny className="app-icon-base" />;
        case 1: return <WiDayCloudy className="app-icon-base" />;
        case 2: return <WiDayCloudyHigh className="app-icon-base" />;
        case 3: return <WiDaySunnyOvercast className="app-icon-base" />;
        case 45: return <WiDayFog className="app-icon-base" />;
        case 48: return <WiDayFog className="app-icon-base" />;
        case 51: return <WiDaySprinkle className="app-icon-base" />;
        case 53: return <WiDayRain className="app-icon-base" />;
        case 55: return <WiDayRainWind className="app-icon-base" />;
        case 56: return <WiDaySleetStorm className="app-icon-base" />;
        case 57: return <WiDaySleetStorm className="app-icon-base" />;
        case 61: return <WiDayShowers className="app-icon-base" />;
        case 63: return <WiDayShowers className="app-icon-base" />;
        case 65: return <WiDayShowers className="app-icon-base" />;
        case 66: return <WiDaySleetStorm className="app-icon-base" />;
        case 67: return <WiDaySleetStorm className="app-icon-base" />;
        case 71: return <WiDaySnow className="app-icon-base" />;
        case 73: return <WiDaySnowWind className="app-icon-base" />;
        case 75: return <WiDaySnowWind className="app-icon-base" />;
        case 77: return <WiDaySnowWind className="app-icon-base" />;
        case 80: return <WiDayRain className="app-icon-base" />;
        case 81: return <WiDayRainWind className="app-icon-base" />;
        case 82: return <WiDayRainWind className="app-icon-base" />;
        case 85: return <WiDaySnowWind className="app-icon-base" />;
        case 86: return <WiDaySnowWind className="app-icon-base" />;
        case 95: return <WiDayThunderstorm className="app-icon-base" />;
        case 96: return <WiDayThunderstorm className="app-icon-base" />;
        case 99: return <WiDayThunderstorm className="app-icon-base" />;
    }

    return (
        <WiBarometer className="app-icon-base" />
    );
}

