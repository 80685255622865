import React, { useState, useEffect} from "react"
import { useParams, useNavigate, Link } from 'react-router-dom'

import {City} from "../Types/City"
import {Card, Image, Flex} from "antd"
import logo from "../Images/weatherPlaceHolder.png"
import { on } from "events"
import { UrlTypes } from "../Types/Weather"
import {selectUrl} from "../CityPage"
import { Icon } from '../Components/Icon'
  
export type CityCardProps = {
    city: City
    onClick: (id: string) => void
    imageUrl: string
    index: string
}

const { Meta } = Card

const CityCard = ({ city, onClick, imageUrl, index} : CityCardProps) => {

    const [_imgUrl, setImgUrl] = useState(logo)

    useEffect(() => {
        setImgUrl(imageUrl)
    })

    return <Card
        key={index}
        onClick={() => {
            onClick(city.id)
        }}
        hoverable
        className={"app-city-card"}
        cover={<Image preview={false} src={_imgUrl} fallback={logo} draggable={false}></Image>}

        >
        <Meta title={<Flex gap="small">{city.name}

            <Icon size={32} weather={city.daily_weather[0]} color='#000' />
        </Flex>} description={city.country} />
    </Card>
}
export default CityCard

