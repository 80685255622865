export enum UrlFields {
  DOG = "dog_image_url",
  CAT = "cat_image_url",
  WOMAN = "woman_image_url",
  MAN = "man_image_url",
}
export enum UrlTypes {
  DOG = "Dogs",
  CAT = "Cats",
  WOMAN = "Woman",
  MEN = "Men",
}

export interface DailyWeather {
  id: string;
  created_at: string;
  updated_at: string;
  is_deleted: boolean;
  date: string;
  weather_code: number;
  temperature_2m_max: number;
  temperature_2m_min: number;
  apparent_temperature_max: number;
  apparent_temperature_min: number;
  sunrise: number;
  sunset: number;
  daylight_duration: number;
  sunshine_duration: number;
  uv_index_max: number;
  uv_index_clear_sky_max: number;
  precipitation_sum: number;
  rain_sum: number;
  showers_sum: number;
  snowfall_sum: number;
  precipitation_hours: number;
  precipitation_probability_max: number;
  wind_speed_10m_max: number;
  wind_gusts_10m_max: number;
  wind_direction_10m_dominant: number;
  shortwave_radiation_sum: number;
  et0_fao_evapotranspiration: number;
  image_url: null | string;
  woman_image_url: null | string;
  man_image_url: null | string;
  cat_image_url: null | string;
  dog_image_url: null | string;
  city_image_url: null;
  city: string;
}

export interface CurrentWeather {
  id: string;
  created_at: string;
  updated_at: string;
  is_deleted: boolean;
  city: string;
  date: string;
  temperature_2m: number;
  relative_humidity_2m: number;
  apparent_temperature: number;
  is_day: boolean;
  precipitation: number;
  rain: number;
  showers: number;
  snowfall: number;
  weather_code: number;
  cloud_cover: number;
  pressure_msl: number;
  surface_pressure: number;
  wind_speed_10m: number;
  wind_direction_10m: number;
  wind_gusts_10m: number;
}

export interface HourlyWeather {
  id: string;
  created_at: string;
  updated_at: string;
  city: string;
  date: string;
  temperature_2m: number;
  relative_humidity_2m: number;
  dew_point_2m: number;
  apparent_temperature: number;
  precipitation_probability: number;
  precipitation: number;
  rain: number;
  showers: number;
  snowfall: number;
  snow_depth: number;
  weather_code: number;
  pressure_msl: number;
  surface_pressure: number;
  cloud_cover: number;
  cloud_cover_low: number;
  cloud_cover_mid: number;
  cloud_cover_high: number;
  visibility: number;
  evapotranspiration: number;
  et0_fao_evapotranspiration: number;
  vapour_pressure_deficit: number;
  wind_speed_10m: number;
  wind_direction_10m: number;
  wind_gusts_10m: number;
}
