import React, { useEffect, useState, useMemo } from "react";
import { DailyWeather } from "../Types/Weather";
import { City } from "../Types/City";
import { Card, Image, Flex, Tooltip } from "antd";
import logo from "../Images/weatherPlaceHolder.png";
import { Icon } from "../Components/Icon";
import {
  Temperature,
  Precipitation,
  Wind,
  ShortWaveRadiationSum,
  Duration,
  Percentage,
  Direction,
} from "./UnitsWithIcons";

type WeatherGirlCardProps = {
  weather: DailyWeather;
  onClick: (id: string) => void;
  index: number;
  imageUrl: string;
  city: City;
};

const { Meta } = Card;

const WeatherGirlCard = ({
  weather,
  onClick,
  index,
  imageUrl,
  city,
}: WeatherGirlCardProps) => {
  const [imgUrl, setImgUrl] = useState(logo);
  const [arrow, setArrow] = useState("Show");

  const mergedArrow = useMemo(() => {
    if (arrow === "Hide") {
      return false;
    }

    if (arrow === "Show") {
      return true;
    }

    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  const description = (weather: DailyWeather) => {
    const gap = "small";
    return (
      <div style={{ color: "white" }}>
        <h4>Temperature</h4>
        <Tooltip
          placement="right"
          title={"Air temperature at 2 meters above ground"}
          arrow={mergedArrow}
        >
          <Flex gap={5}>
            Temperature Min:{" "}
            <Temperature>{weather.temperature_2m_min}</Temperature>Max:{" "}
            <Temperature>{weather.temperature_2m_max}</Temperature>
          </Flex>
        </Tooltip>

        <Tooltip
          placement="right"
          title={
            "Apparent temperature is the perceived feels-like temperature combining wind chill factor, relative humidity and solar radiation"
          }
          arrow={mergedArrow}
        >
          <Flex gap={5}>
            Feels like Min:{" "}
            <Temperature>{weather.apparent_temperature_min}</Temperature> Max:{" "}
            <Temperature>{weather.apparent_temperature_max}</Temperature>
          </Flex>
        </Tooltip>
        <Tooltip
          placement="right"
          title={
            "Wind speed at 10 meters above ground. Wind speed on 10 meters is the standard level."
          }
          arrow={mergedArrow}
        >
          <Flex gap={gap}>
            Wind Speed: <Wind>{weather.wind_speed_10m_max}</Wind>
          </Flex>
        </Tooltip>
        <Tooltip
          placement="right"
          title={"Wind direction at 10 meters above ground"}
          arrow={mergedArrow}
        >
          <Flex gap={gap}>
            Wind Direction:{" "}
            <Direction>{weather.wind_direction_10m_dominant}</Direction>
          </Flex>
        </Tooltip>
        <h4>Sunlight</h4>
        <Tooltip
          placement="right"
          title={"Time of daylight per day"}
          arrow={mergedArrow}
        >
          <Flex gap={gap}>
            Daylight Duration: <Duration>{weather.daylight_duration}</Duration>
          </Flex>
        </Tooltip>

        <Tooltip
          placement="right"
          title={
            "The time of sunshine is determined by calculating direct normalized irradiance exceeding 120 W/m², following the WMO definition. Sunshine duration will consistently be less than daylight duration due to dawn and dusk."
          }
          arrow={mergedArrow}
        >
          <Flex gap={gap}>
            Sunshine Duration: <Duration>{weather.sunshine_duration}</Duration>
          </Flex>
        </Tooltip>

        {/* https://www.who.int/news-room/questions-and-answers/item/radiation-the-ultraviolet-(uv)-index */}
        <Tooltip
          placement="right"
          title={
            <>
              This number basically indicates how fast you'll burn. Daily
              maximum in UV Index starting from 0 to 11! Please follow the
              official{" "}
              <a href="https://www.who.int/news-room/questions-and-answers/item/radiation-the-ultraviolet-(uv)-index">
                WMO guidelines
              </a>{" "}
              for ultraviolet index.
            </>
          }
          arrow={mergedArrow}
        >
          <Flex gap={gap}>
            UV Index Max: {Math.floor(weather.uv_index_max)}
          </Flex>
        </Tooltip>

        <Tooltip
          placement="right"
          title={
            "The sum of solar radiation on a given day in Megajoules. For anyone who cares."
          }
          arrow={mergedArrow}
        >
          <Flex gap={gap}>
            Shortwave Radiation:{" "}
            <ShortWaveRadiationSum>
              {Math.floor(weather.shortwave_radiation_sum)}
            </ShortWaveRadiationSum>
          </Flex>
        </Tooltip>

        <Tooltip
          placement="right"
          title={
            "Daily sum of ET₀ Reference Evapotranspiration of a well watered grass field. Also known as how much evaporation we can expect."
          }
          arrow={mergedArrow}
        >
          <Flex gap={gap}>
            Evapotranspiration:{" "}
            <Precipitation>{weather.et0_fao_evapotranspiration}</Precipitation>
          </Flex>
        </Tooltip>
        {/* <div>Sunrise: {weather.sunrise}</div>
        <div>Sunset: {weather.sunset}</div> */}

        {/* <div>UV Index Clear Sky Max: {weather.uv_index_clear_sky_max}</div> */}
        {/* <div>Wind Gusts: {weather.wind_gusts_10m_max}</div> */}
        <h4>Precipitation</h4>
        <Tooltip
          placement="right"
          title={
            "Sum of daily precipitation (including rain, showers and snowfall)"
          }
          arrow={mergedArrow}
        >
          <Flex gap={gap}>
            Precipitation:{" "}
            <Precipitation>{weather.precipitation_sum}</Precipitation>
          </Flex>
        </Tooltip>
        {/* TODO, get the mean probability, rather than the MAX */}
        <Tooltip
          placement="right"
          title={"Probability of precipitation"}
          arrow={mergedArrow}
        >
          <Flex gap={gap}>
            Precipitation Probability:{" "}
            <Percentage>{weather.precipitation_probability_max}</Percentage>
          </Flex>
          {/* <div>Precipitation Hours: {weather.precipitation_hours}</div> */}
        </Tooltip>
        <Tooltip
          placement="right"
          title={
            "Sum of daily rain. (Rain will bring continuous wet weather for hours at a time, then it will be clear)."
          }
          arrow={mergedArrow}
        >
          <Flex gap={gap}>
            Rain Sum: <Precipitation>{weather.rain_sum}</Precipitation>
          </Flex>
        </Tooltip>
        <Tooltip
          placement="right"
          title={
            "Sum of daily showers. (Showers imply the weather will change a lot during the day with rain on and off)."
          }
          arrow={mergedArrow}
        >
          <Flex gap={gap}>
            Showers Sum: <Precipitation>{weather.showers_sum}</Precipitation>
          </Flex>
        </Tooltip>
        <Tooltip
          placement="right"
          title={"Sum of daily snowfall"}
          arrow={mergedArrow}
        >
          <Flex gap={gap}>
            Snowfall Sum: <Precipitation>{weather.snowfall_sum}</Precipitation>
          </Flex>
        </Tooltip>
      </div>
    );
  };

  useEffect(() => {
    setImgUrl(imageUrl);
  });

  return (
    <Card
      key={index}
      hoverable={true}
      className={`app-card app-card-detail-card app-card-detail-card-header`}
      cover={
        <Image
          preview={false}
          src={imgUrl}
          fallback={logo}
          draggable={false}
          style={{ borderRadius: 0 }}
        ></Image>
      }
    >
      <Meta
        title={
          <Flex gap="small">
            <div>{new Date(weather.date).toDateString()},</div>
            <div>{city.name},</div>
            <div>{city.country}</div>
            <Icon size={32} weather={weather} color="#000" />
          </Flex>
        }
        description={description(weather)}
      />
    </Card>
  );
};
export default WeatherGirlCard;
