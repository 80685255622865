import {
  CameraOutlined,
  SettingOutlined,
  CalendarOutlined,
  ControlOutlined,
  UserOutlined,
  StopOutlined,
  PlusOutlined,
  BarChartOutlined,
  DollarOutlined,
  HeartFilled,
  AuditOutlined,
  ScheduleOutlined,
  QuestionCircleOutlined,
  FileImageOutlined
} from '@ant-design/icons'

// import './fontawesome-free-6.5.1-web/css/fontawesome.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDog, faCat, faFemale, faMale } from '@fortawesome/free-solid-svg-icons'

// import SatelliteIcon from '../CustomIcons/SatelliteIcon'
import { Menu, Badge } from 'antd'
import React, { useContext, useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { StoreContext } from '../Store'
// import { SummaryContext } from '../Notifications/SummaryContext'
// import { ready_for_geo_status } from '../ImageQATasks/ScheduledTaskStatii'
import Logo from "./Logo"

// Object that maps Django permission strings to antd Menu items
const generatePermissionItems = (taskSummaryData) => {
  // Calculate totals for each group
  let taskingRequestTotalCount =
    taskSummaryData.tasking_requests__pendingapproval +
    taskSummaryData.tasking_requests__scheduled
  let imageQaTotalCount =
    taskSummaryData.scheduled_tasks__geolocation +
    taskSummaryData.scheduled_tasks__qa
  return {
    'user_management.view_schedule': {
      label: 'Schedule',
      key: 'schedule',
      icon: <ScheduleOutlined />,
    },
    'user_management.view_tasks': {
      label:
        taskingRequestTotalCount > 0 ? (
          <Badge
            count={taskingRequestTotalCount}
            size="small"
            className="wyvern-menu-badge"
          >
            Tasks
          </Badge>
        ) : (
          'Tasks'
        ),
      key: 'task',
      icon: <CalendarOutlined />,
      children: [
        {
          label:
            taskingRequestTotalCount > 0 ? (
              <Badge
                count={taskingRequestTotalCount}
                size="small"
                className="wyvern-menu-badge"
              >
                Tasks
              </Badge>
            ) : (
              'Tasking Requests'
            ),
          key: 'task',
          icon: <CalendarOutlined />,
        },
        {
          type: 'divider',
        },
        {
          label: 'Create Tasking Request',
          key: 'task/create',
          icon: <PlusOutlined />,
        },
      ],
    },
    // 'user_management.view_images': {
    //   label:
    //     imageQaTotalCount > 0 ? (
    //       <Badge
    //         count={imageQaTotalCount}
    //         size="small"
    //         className="wyvern-menu-badge"
    //       >
    //         Image QA
    //       </Badge>
    //     ) : (
    //       'Image QA'
    //     ),
    //   key: 'images',
    //   icon: <CameraOutlined />,
    //   children: [
    //     {
    //       label:
    //         taskSummaryData.scheduled_tasks__geolocation > 0 ? (
    //           <Badge
    //             count={taskSummaryData.scheduled_tasks__geolocation}
    //             size="small"
    //             className="wyvern-menu-badge"
    //           >
    //             Ready for Geolocation
    //           </Badge>
    //         ) : (
    //           'Ready for Geolocation'
    //         ),
    //       key: `images?status=${ready_for_geo_status}`,
    //       icon: <GlobalOutlined />,
    //     },
    //     {
    //       type: 'divider',
    //     },
    //     {
    //       label:
    //         taskSummaryData.scheduled_tasks__qa > 0 ? (
    //           <Badge
    //             count={taskSummaryData.scheduled_tasks__qa}
    //             size="small"
    //             className="wyvern-menu-badge"
    //           >
    //             Ready for QA
    //           </Badge>
    //         ) : (
    //           'Ready for QA'
    //         ),
    //       key: 'images?status=ready_for_qa',
    //       icon: <AuditOutlined />,
    //     },
    //   ],
    // },
    'user_management.view_reports': {
      label: 'Reporting',
      key: 'reporting',
      icon: <BarChartOutlined />,
      children: [
        {
          label: 'Billing',
          key: 'billing',
          icon: <DollarOutlined />,
        },
        {
          label: 'Schedule',
          key: 'schedule_export',
          icon: <ScheduleOutlined />,
        },
      ],
    },
    'user_management.view_operational_settings': {
      label: 'Settings',
      key: 'settings',
      icon: <ControlOutlined />,
      children: [
        {
          label: 'Users',
          key: 'settings/users',
          icon: <UserOutlined />,
        },
        {
          label: 'Exclusion Zones',
          key: 'settings/exclusion_zones',
          icon: <StopOutlined />,
        },
      ],
    },
  }
}

function formMenuItems(user, taskSummaryData) {
  // Generate permission items via task summary information (for badges)
  // let permission_items = generatePermissionItems(taskSummaryData)

  // Form initial list containing logo and user dropdown
  var returnList = [
    {
      label: <Logo />,
      key: '/',
      className: 'app-header-env',

    },

    {
      label: 'Cats',
      key: 'Cats',
      icon: <FontAwesomeIcon icon={faCat} />,

    },
    {
      label: 'Dogs',
      key: 'Dogs',
      icon: <FontAwesomeIcon icon={faDog} />,
    },
    // {
    //   label: 'Woman',
    //   key: 'Woman',
    //   icon: <FontAwesomeIcon icon={faFemale} />,
    // },
    // {
    //   label: 'Men',
    //   key: 'Men',
    //   icon: <FontAwesomeIcon icon={faMale} />,
    // },

    // {
    //   label: `Welcome, ${user.username}!`,
    //   key: 'user_menu',
    //   className: 'app-header-welcome-user',
    //   children: [
    //     {
    //       label: <a href="/accounts/logout/">Log out</a>,
    //       key: 'logout',
    //     },
    //   ],
    // },

  ]

  // If permission is in user permission array, add to return menu item list
  // for (const [permission, menu_item] of Object.entries(permission_items)) {
  //   if (user.permissions.includes(permission)) {
  //     returnList.splice(-2, 0, menu_item) // Place items after the Wyvern header but before admin/halp links
  //   }
  // }

  // Check if user has Admin access (is_staff), insert admin menu item if True
  // This is a boolean field separate from user permissions.
  // if (user.is_staff) {
  //   returnList.splice(-1, 0, {
  //     label: <a href="/admin/">Admin Panel</a>,
  //     key: 'admin',
  //     icon: <SettingOutlined />,
  //   })
  // }

  return returnList
}

const App = () => {
  const [current, setCurrent] = useState('menu')
  const navigate = useNavigate()
  const context = useContext(StoreContext)

  const loc = useLocation()

  const onClick = (e) => {
    // If the key is empty, do not use react navigate
    if (e.key !== '') {
      setCurrent(e.key)
      navigate('/app/' + e.key)
    } else if (
      e.key === '/'
    ) {
      setCurrent(e.key)
      navigate('/app/')
    }
  }

  useEffect(() => {
    if (loc && loc.pathname) {
      let routeBase = loc.pathname.split('/').filter((x) => x)[0]
      if (current === 'menu' && routeBase) {
        setCurrent(routeBase)
      }
    }
  }, [loc, setCurrent])

  return (

    <Menu
      onClick={onClick}
      selectedKeys={[current]}
      theme="dark"
      mode="horizontal"
      items={formMenuItems(context.data.user)}
      className="app-header-menu"

    />

  )
}
export default App
